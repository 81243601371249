<template>
  <b-card>
    <validation-observer ref="formStudent" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <h5 class="mb-3">Generate Kode Kuesioner Per Sesi</h5>

        <b-col cols="12">
          <b-form-group
            label="Nama Blok"
            label-for="h-course"
            label-cols-md="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Blok"
              rules="required"
            >
              <v-select
                :options="courses"
                :reduce="(item) => item.id"
                placeholder="Nama Blok"
                :getOptionLabel="(item) => item.name"
                v-model="model.blockId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Type" label-for="h-type" label-cols-md="2">
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <v-select
                :options="categories"
                :reduce="(item) => item.id"
                placeholder="Type"
                label="name"
                v-model="model.category"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Jumlah Mahasiswa"
            label-for="h-total-student"
            label-cols-md="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Jumlah Mahasiswa"
              rules="required|minValue:1"
            >
              <b-form-input
                type="number"
                id="h-total-student"
                placeholder="Jumlah Mahasiswa"
                v-model="model.studentCount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="$router.go(-1)"
          >
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
          >
            Simpan
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.action,
        api: "questionnaire-session",
        redirect: "feedback-lecture",
      },
      courses: [],
      categories: [],
      model: {},
    };
  },
  created() {
    const _ = this;
    _.getCourses();
    _.getCategories();
  },
  methods: {
    getCourses() {
      const _ = this;
      _.$axios.get(`questionnaire-session/blocks`).then((res) => {
        // console.log(res.data);
        _.courses = res.data.data;
      });
    },
    getCategories() {
      const _ = this;
      _.$axios.get(`questionnaire-session/categories`).then((res) => {
        _.categories = res.data.data;
      });
    },
    save() {
      const _ = this;
      _.$axios.post(_.config.api, _.model).then((res) => {
        const code = res.data.data.sessionCode;
        _.$router.push(`/feedback-lecturer/${code}/show`);
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

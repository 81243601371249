var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formStudent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('h5',{staticClass:"mb-3"},[_vm._v("Generate Kode Kuesioner Per Sesi")]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Blok","label-for":"h-course","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Nama Blok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.courses,"reduce":function (item) { return item.id; },"placeholder":"Nama Blok","getOptionLabel":function (item) { return item.name; }},model:{value:(_vm.model.blockId),callback:function ($$v) {_vm.$set(_vm.model, "blockId", $$v)},expression:"model.blockId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"h-type","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categories,"reduce":function (item) { return item.id; },"placeholder":"Type","label":"name"},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Jumlah Mahasiswa","label-for":"h-total-student","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Jumlah Mahasiswa","rules":"required|minValue:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"h-total-student","placeholder":"Jumlah Mahasiswa"},model:{value:(_vm.model.studentCount),callback:function ($$v) {_vm.$set(_vm.model, "studentCount", $$v)},expression:"model.studentCount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Kembali ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }